import React, { useState } from "react";
import "./styles.scss";
import { Row, Col } from "react-bootstrap";
import Particles from "react-particles-js";
import AnimationContainer from "components/animation-container";
import BaffleText from "components/baffle-text";
import Typewriter from "typewriter-effect";
import ThemeContext from "../../context";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      error: false,
      show: false,
      status: "",
    };
    this.show = this.show.bind(this);
  }
  static contextType = ThemeContext;

  show() {
    this.setState({ show: true });
  }

  check(val) {
    if (this.state.error && val === "") {
      return false;
    } else {
      return true;
    }
  }

  submit() {
    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.message === ""
    ) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });
    }
  }

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="contact"
        style={{ height: this.context.height }}
      >
        <Row>
          <Col md={2} className="side">
            <h2>
              <BaffleText
                text="Contact"
                revealDuration={500}
                revealDelay={500}
                parentMethod={this.show}
                callMethodTime={1100}
              />
            </h2>
          </Col>
          <Col md={5} className="form">
            {this.particles()}
            {this.form()}
          </Col>
          <Col md={5} className="map">
            {this.map()}
          </Col>
        </Row>
      </section>
    );
  }

  form() {
    if (this.state.show || this.context.height === "auto") {
      return (
        <AnimationContainer delay={0} animation="fadeInUp fast">
          <form
            className="form-container"
            action={process.env.GATSBY_FORMSPREE_VARIABLE}
            method="POST"
          >
            <div className="line-text">
              <h4>
                <Typewriter
                  options={{
                    strings: ["Get In Touch"],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </h4>
              <AnimationContainer delay={50} animation="fadeInUp fast">
                <div className="form-group">
                  <input
                    type="text"
                    className={`name ${
                      this.check(this.state.name) ? "" : "error"
                    }`}
                    placeholder="Name"
                    name="name"
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
              </AnimationContainer>
              <AnimationContainer delay={100} animation="fadeInUp fast">
                <div className="form-group">
                  <input
                    type="text"
                    className={`email ${
                      this.check(this.state.email) ? "" : "error"
                    }`}
                    placeholder="Email"
                    name="_replyto"
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>
              </AnimationContainer>
              <AnimationContainer delay={150} animation="fadeInUp fast">
                <div className="form-group">
                  <input
                    type="text"
                    name="number"
                    className="phone"
                    placeholder="Phone"
                    onChange={(e) => this.setState({ phone: e.target.value })}
                  />
                </div>
              </AnimationContainer>
              <AnimationContainer delay={200} animation="fadeInUp fast">
                <div className="form-group">
                  <textarea
                    className={`message ${
                      this.check(this.state.message) ? "" : "error"
                    }`}
                    placeholder="Message"
                    name="message"
                    onChange={(e) => this.setState({ message: e.target.value })}
                  ></textarea>
                </div>
              </AnimationContainer>
              <AnimationContainer delay={250} animation="fadeInUp fast">
                <div className="submit">
                  <button
                    className={`hover-button ${
                      this.state.error ? "error" : ""
                    }`}
                    onClick={(e) => this.submit()}
                    value="Send"
                  >
                    <span>Send Message</span>
                  </button>
                </div>
              </AnimationContainer>
            </div>
          </form>
        </AnimationContainer>
      );
    }
  }

  particles() {
    return (
      <Particles
        className="particles"
        params={{
          particles: {
            number: {
              value: 50,
              density: {
                enable: false,
                value_area: 4000,
              },
            },
            line_linked: {
              enable: true,
              opacity: 0.5,
              color: "#ead136",
            },
            size: {
              value: 0.5,
            },
          },
          retina_detect: true,
        }}
      />
    );
  }

  map() {
    if (this.state.show || this.context.height === "auto") {
      return (
        <AnimationContainer
          delay={1000}
          animation="fadeIn fast"
          height={this.context.height}
        >
          <iframe
            title="map"
            width="100%"
            height="100%"
            src="https://maps.google.com/maps?q=key%20biscayne&t=k&z=11&ie=UTF8&iwloc=&output=embed"
          />
        </AnimationContainer>
      );
    }
  }
}

export default Contact;
